import PetProfile from "../components/PetProfile";
import '../assets/css/Tracker.css';

function Tracker(props) {
	let trackedName = new URL(window.location).searchParams.get('name') || false;

	return(
		<div>
			<div className="trackerContent">
				<PetProfile name={trackedName} />
			</div>
		</div>
	)
}

export default Tracker;