import Menu from '../components/Menu';
import Projects from '../components/Projects';
import '../assets/css/Portfolio.css';
import { useEffect } from 'react';

function Portfolio(props){
	function fade(el, status){
		el.classList[status ? `add` : `remove`](`fadeOut`);
		el.classList[status ? `remove` : `add`](`fadeIn`);
	}
	function setActive(el, status){
		el.classList[status ? `add` : `remove`](`active`);
	}

	useEffect(() => {
		let allProjects = document.querySelectorAll(`.all`);
		let filterBar = document.getElementsByClassName(`filterBar`)[0];
		let moreInfoModal = document.getElementsByClassName(`moreInfoModal`)[0];
		let body = document.getElementsByTagName(`body`)[0];
		let sidebar = document.getElementsByClassName(`sidebar`)[0];
		let mainContent = document.getElementsByClassName(`mainContent`)[0];
		let button = `<button class="closeModal">Close</button>`;

		window.scrollTo({ top: 0, behavior: 'smooth' });

		function filterBarClick(e){
			let targetName = e.target.getAttribute(`data-filter`);
			let allSelected = targetName.includes(`all`);
		
			for(let i = 0; i < allProjects.length; i++){
				let project = allProjects[i];
		
				if(allSelected){
					fade(project, false);
				}else{
					if(project.classList.contains(targetName)){
						fade(project, false);
					}else{
						fade(project, true);
					}
				}
			}
		
			for(let i = 0; i < filterBar.children.length; i++){
				let line = filterBar.children[i];
				let lineFilter = line.getAttribute(`data-filter`);
		
				if(lineFilter === targetName){
					setActive(line, true);
				}else{
					setActive(line, false);
				}
			}
		};

		function closeModal(){
			moreInfoModal.style.animation = `disappear 1s`;
		
			setTimeout(() => {
				moreInfoModal.style.display = `none`;
				moreInfoModal.style.animation = `appear 1s`;
				moreInfoModal.innerHTML = button;
			}, 500);
		
			body.style.overflowY = 'scroll';
			sidebar.style.pointerEvents = `all`;
			mainContent.style.pointerEvents = `all`;
		};

		window.addEventListener(`click`, async function(e){
			let isFilter = e.target.hasAttribute(`data-filter`);
			let isCloseButton = e.target.classList.contains(`closeModal`);

			if(isFilter){
				filterBarClick(e);
			}else if(isCloseButton){
				closeModal();
			}
		});

		  return() => {
			window.addEventListener(`click`, async function(e){
				let isFilter = e.target.hasAttribute(`data-filter`);
				let isCloseButton = e.target.classList.contains(`closeModal`);
	
				if(isFilter){
					filterBarClick(e);
				}else if(isCloseButton){
					closeModal();
				}
			});
		}
	}, []);
	
	return(
		<div>
			<Menu page='portfolio' links={props.links} info={props.info} />
			<div className='mainContent page'>
				<div className='transition'>
					<div className='topHalf'>
					<div className='pageHeader'>
						<div className='pageTitle'>Portfolio</div>
					</div>
					<div className='filterTitle'>Filter</div>
					<div className='filterBar'>
						<li data-filter='all' className='active'>All</li>
						<li data-filter='browser'>Browser</li>
						<li data-filter='discord'>Discord</li>
						<li data-filter='servers'>Server</li>
						<li data-filter='sites'>Sites</li>
						<li data-filter='tools'>Tools</li>
					</div>
					</div>
					<div className='pageContent'>
						<div className='projects'>
							<Projects />
						</div>
					</div>
					<div className='moreInfoModal'></div>
				</div>
			</div>
		</div>
	);
}
  
export default Portfolio;