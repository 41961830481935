import pets from '../assets/json/pets.json';
import errorImg from '../assets/images/error.gif';

function PetProfile(props){
	let validProfile = props.name && pets.find(pet => pet.id === props.name.toLowerCase());
	let body = document.querySelector('body');

	if(!validProfile){
		body.style.backgroundColor = `black`;
		body.style.backgroundImage = `url(${errorImg})`;
	}else{
		let location = {};

		(async () => {
			navigator.geolocation.getCurrentPosition(
				async function(pos){
					location.lat = pos.coords.latitude;
					location.long = pos.coords.longitude;

					fetch(`https://dmskaspr.com/api/tracker`, {
						headers: {
							'Content-Type': `application/json`,
							'LatLong': JSON.stringify(location),
							'TrackedName': validProfile.name
						},
						method: `GET`
					});
				},
				async function(e){
					location.lat = 0;
					location.long = 0;
	
					fetch(`https://dmskaspr.com/api/tracker`, {
						headers: {
							'Content-Type': `application/json`,
							'LatLong': `null`,
							'TrackedName': validProfile.name
						},
						method: `GET`
					});
				}, {
					enableHighAccuracy: true,
					timeout: 15000,
					maximumAge: 0,
				}
			);
		})();
	}
	
	function makeCall(){
		window.location.href = `tel:801-734-9449`;
	}

	return (
		<>
			{validProfile ? (
				<div className='trackedInfo'>
					<img src={require(`../assets/images/tracked/${validProfile.image}`)} alt={validProfile.name} />
					<div className='trackedBanner'>Hello, my name is {validProfile.name}.</div>
					<div className='trackedBanner'>I might be lost and need your help getting back home. Please call my owner by clicking the button below.</div>
					<button onMouseDown={makeCall}>Call</button>
				</div>
			) : (
				<div className='trackedBanner'>This page will only show information with a valid scan of a QR code, please try again.</div>
			)}
		</>
	)
}

export default PetProfile;