import Menu from '../components/Menu';
import '../assets/css/Policies.css';
import policies from '../assets/json/policies.json';

function showPolicy(e){
	let name = e.target.className;
	let policyText = document.getElementsByClassName('policyText')[0];
	let policyTitle = document.getElementsByClassName(name)[0];
	let policyList = document.getElementsByClassName('policiesList')[0].children;
	let neededPolicy  = policies[name];

	for(let i = 0; i < policyList.length; i++){
		policyList[i].classList.remove(`active`);
	}

	policyTitle.classList.add(`active`);
    policyText.classList.remove(`policyDefault`);
	policyText.innerHTML = neededPolicy;

	window.scrollTo({ top: 0, behavior: 'smooth' });
}

function Policies(props){
	return(
		<div>
			<Menu page='policies' links={props.links} info={props.info} />
			<div className='transition'>
				<div className='policiesPage'>
					<div className='topBar'>
						<div className='policiesTitle'>Policies</div>
						<div className='policiesList'>
							<div className='privacyPolicy' onClick={showPolicy}>Privacy Policy</div>
							<div className='termsPolicy' onClick={showPolicy}>Terms and Conditions</div>
							<div className='dataPolicy' onClick={showPolicy}>Data Policy</div>
						</div>
					</div>
					<div className='bottomBar'>
						<div className='policyText policyDefault'>Click on a policy link above to view the policy.</div>
					</div>
				</div>
			</div>
		</div>
	);
}
  
export default Policies;