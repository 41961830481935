import { useEffect } from 'react';

function Matrix(){
	useEffect(() => {
		let canvas = document.querySelector(`canvas`);
		let context = canvas && canvas.getContext(`2d`);
		let matrixInterval;
		let matrix = {
			characters: [`゠`,`ア`,`イ`,`ウ`,`エ`,`オ`,`カ`,`キ`,`ク`,`ケ`,`コ`,`サ`,`シ`,`ス`,`セ`,`ソ`,`タ`,`チ`,`ツ`,`テ`,`ト`,`ナ`,`ニ`,`ヌ`,`ネ`,`ノ`,`ハ`,`ヒ`,`フ`,`ヘ`,`ホ`,`マ`,`ミ`,`ム`,`メ`,`モ`,`ヤ`,`ユ`,`ヨ`,`ラ`,`リ`,`ル`,`レ`,`ワ`,`ヰ`,`ヱ`,`ヲ`,`ン`,`ヺ`,`・`,`ー`,`ヽ`,`ヿ`,`ɵ`,`ɷ`,`ɸ`,`ɹ`,`ɻ`,`ʂ`,`ʆ`,`ϔ`,`ϗ`,`Ϛ`,`ϛ`,`Ϝ`,`ϝ`,`Ϟ`,`ϟ`,`Ϡ`,`ϡ`,`Ϣ`,`Ϧ`,`ϧ`,`ϥ`,`ϩ`,`Ϫ`,`Ϯ`,`ϰ`,`ϴ`,`ϵ`,`϶`,`Ұ`,`ұ`,`Ҳ`,`ҹ`,`Ӂ`,`ӂ`,`Ӄ`,`ӆ`,`Ӌ`,`Ԅ`,`Ԇ`,`0`,`1`,`2`,`3`,`4`,`5`,`6`,`7`,`8`,`9`],
			drops: [],
			fontSize: 14,
			hue: 0,
			hueFw: false,
			matrixSpeed: 35,
			waveColor: {
				red: 55,
				green: 160,
				blue: 220,
			},
		};
		
		async function draw(){
			context.fillStyle = `rgba(0,0,0,0.095)`;
			context.fillRect(0, 0, canvas.width, canvas.height);
			context.fillStyle = `#000`;
			context.font = `${matrix.fontSize}px regular`;
		
			for(let i = 0; i < matrix.drops.length; i++){
				let text = matrix.characters[Math.floor(Math.random() * matrix.characters.length)];
				
				context.fillStyle = `rgba(${matrix.waveColor.red},${matrix.waveColor.green},${matrix.waveColor.blue}, 1)`;
				context.fillText(text, i * matrix.fontSize, matrix.drops[i] * matrix.fontSize);
				matrix.drops[i]++;
		
				if((Math.random() > 0.98) && (Math.random() > 0.5)){
					matrix.drops[i] = 0;
				}
			}
		};
		
		async function initMatrix(){
			clearInterval(matrixInterval);
		
			canvas.height = window.innerHeight;
			canvas.width = window.innerWidth;
		
			for(let i = 0; i < canvas.width/matrix.fontSize; i++){
				matrix.drops.push(1);
			}
		
			matrixInterval = setInterval(draw, matrix.matrixSpeed);
		};
		
		window.addEventListener(`resize`, async function(){
			if(canvas.width !== window.innerWidth){
				clearInterval(matrixInterval);
		
				await initMatrix();
			}
		});

		initMatrix();

		return() => {
			clearInterval(matrixInterval);

			window.removeEventListener(`resize`, async function(){
				if(canvas.width !== window.innerWidth){
					clearInterval(matrixInterval);
			
					await initMatrix();
				}
			});
		};
	}, []);

	return <canvas className='matrix'></canvas>;
}
  
export default Matrix;